import React from "react";
import './Footer.css';
import Logo from '../../../Assets/digiesim logo-02.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useNavigate } from 'react-router-dom';
import Tictok from '../../../Assets/tik-tok_2582617.png'
import countryData from 'country-data'; // Importing the correct country data package
import Star from '../../../Assets/Star.png'

const Footer = () => {
    const navigate = useNavigate();

    const countryIsoCodes = {
        "Europe": "EUR",
        "USA": "USA",
        "Asia": "APC",
        "Latin America": "LAT"
    };

    const HandleCountryNameClick = (region) => {
        const countryCode = countryIsoCodes[region];
        localStorage.setItem("SelectedCountry", countryCode);
        navigate("/country_plans");
        window.scrollTo(0, 0);
    };


    const handleNavigateRegionPlan = (name, code) => {
        localStorage.setItem('RegionName', name);
        localStorage.setItem('regionCode', code)
        navigate("/region_plans");
        window.scrollTo(0, 0);
    }

    return (
        <div className="Footer">
            <div className="footer_top">
                <div className="sections" id="section_1">
                    <img src={Logo} id="navbarLogo" alt="Digiesim Logo" />
                    <p>digiesim is a global eSIM provider covering over 200 countries. We are a product of {" "}
                        <a href="https://www.acom-solutions.com/" style={{ color: '#383030', marginRight: '3px' }}>
                            Acom Solutions
                        </a> and our ethos is to focus on putting the customer at the heart of everything we do.
                    </p>
                </div>

                <div className="sections" id="section_2">
                    <h3>DIGIESIM</h3>
                    <a href="https://help.digiesim.com/hc/en-us" target="_blank" rel="noopener noreferrer">FAQs</a>
                    <a href="https://help.digiesim.com/hc/en-us" target="_blank" rel="noopener noreferrer">Help Center</a>
                    <a href="https://help.digiesim.com/hc/en-us/sections/26169454424081-The-small-print" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                    <a href="https://help.digiesim.com/hc/en-us/sections/26169454424081-The-small-print" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    <a href="https://help.digiesim.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">Contact Us</a>
                </div>

                <div className="sections" id="section_3">
                    <h3>PRODUCTS</h3>
                    <a href=""
                    
                    // onClick={() => handleNavigateRegionPlan("EUROPE", "EUR")}
                    >Europe+ eSIM</a>
                    <a href="" onClick={() => HandleCountryNameClick("USA")}>USA+ eSIM</a>
                    <a href=""
                    // onClick={() => handleNavigateRegionPlan("ASIA", "APC")}
                    >Asia+ eSIM</a>
                    <a href=""
                    // onClick={() => handleNavigateRegionPlan("LATIN AMERICA", "LAT")}
                    >Latin America+ eSIM</a>
                    <a href="" onClick={() => navigate("/all_countries&regions")}  >All 200+ Destinations</a>
                </div>

                <div className="sections" id="section_4">
                    <h3>FOR BUSINESS</h3>
                    <a href="/business_section">Business Roaming</a>
                </div>
            </div>

            <div className="footer_end">
                <p>© 2024 digiesim · All Rights Reserved</p>
                <h3>|</h3>
                <div className="icons_box">
                    <a href="https://www.facebook.com/profile.php?id=61558124835645" target="_blank" rel="noopener noreferrer">
                        <FacebookIcon id="icon" />
                    </a>
                    <a href="https://www.instagram.com/go.digiesim?igsh=M3ByZjA0Z3liZ3hn" target="_blank" rel="noopener noreferrer">
                        <InstagramIcon id="icon" />
                    </a>
                    <a href="https://www.tiktok.com/@go.digiesim?_t=8p3R9TeRTWa&_r=1" target="_blank" rel="noopener noreferrer">
                        <img src={Tictok} alt="Tictok" style={{ height: '20px', width: '20px', marginLeft: '5px', cursor: 'pointer' }} />
                    </a>

                    {/* <YouTubeIcon id="icon" /> */}
                </div>
            </div>

            <div className="CreatedBy">
                <p >
                    Created by
                </p>

                <a href="https://www.startelelogic.com/" target='_blank' >
                    <span >
                        startelelogic
                    </span>
                    <img src={Star} style={{ height: '18px' }} />
                </a>
            </div>
        </div>
    );
};

export default Footer;
