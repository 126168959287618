import React, { useEffect } from 'react'
import FAQ from './FAQ/FAQ';
import './HomePage.css';
import { useState } from 'react';
import StarImage from '../../Assets/DigiesimStars.png';
import BannerImg from '../../Assets/DigiesmManImage.png'
import { MagnifyingGlass } from 'react-loader-spinner';
import { SearchRounded } from '@mui/icons-material';
import Slider from './Slider/Slider';
import Footer from './Footer/Footer';
import AppSection from './AppSection/AppSection';
import Logo_1 from '../../Assets/Logo_1.png';
import Logo_2 from '../../Assets/Logo_2.png';
import Logo_3 from '../../Assets/Logo_3.png';
import Logo_4 from '../../Assets/Logo_4.png';
import Logo_5 from '../../Assets/Logo_5.png';
import Logo_6 from '../../Assets/Logo_6.png';
import WhyDigiesim from './WhyDigiesim/WhyDigiesim';
import FamousRegions from './Landing Page/FamousRegions';
import FamousCountries from './Landing Page/FamousCountries';
import SessionTimeOutImg from '../../Assets/digiesim.png';
import { Bars } from "react-loader-spinner";
import { H1, H2, H3 } from "../../Components/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import {
    Box,
    Button,
    useMediaQuery,
} from "@mui/material";


const HomePage = () => {

    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate()
    const customId = "forNotShowingMultipleToast"

    const EmailId = localStorage.getItem('EmailId')

    const [sessionTimeoutState, setSessionTimeoutState] = useState(false)
    const [Loading, setLoading] = useState(false);
    const isMobile = useMediaQuery("(max-width: 900px)");
    const UserUuid = localStorage.getItem("UserUuid")


    const handleScroll = (event) => {
        event.preventDefault();
        const targetId = event.currentTarget.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'smooth'
        });
    };

    // getting the login time api function start here 

    const [GetLoginTime, setGetLoginTime] = useState('')
    const TimesGap = 1000 * 60 * 60 * 12 // 12 hours in milliseconds




    // const GetTheLoginTime = () => {

    //     const myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");

    //     const raw = JSON.stringify({
    //         "email_id": EmailId
    //     });

    //     const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: "follow"
    //     };

    //     fetch(`${REACT_APP_BASE_URL}/get_login_time`, requestOptions)
    //         .then((response) => response.json())
    //         .then((result) => {
    //             // console.log(result)
    //             setGetLoginTime(result.login_time);

    //         })
    //         .catch((error) => console.error(error));

    // }


    // useEffect(() => {
    //     if (!GetLoginTime) return; // Wait until login time is fetched

    //     const interval = setInterval(() => {
    //         const currentTime = Date.now(); // Get current time in milliseconds
    //         const timeDifference = currentTime - GetLoginTime;

    //         if (timeDifference >= TimesGap) {
    //             // console.log("Session expired");
    //             setSessionTimeoutState(true);

    //             // Clear interval after session expiry
    //             clearInterval(interval);
    //         }
    //     }, 1000 * 60 * 1); // Check every minutes

    //     // Cleanup interval on component unmount
    //     return () => clearInterval(interval);
    // }, [GetLoginTime]);


    // useEffect(() => {
    //     setInterval(() => {
    //         GetTheLoginTime();
    //     }, 1000 * 60 * 60 * 1); // call api for after one hour 
    // }, []);


    // const HandleLogout = () => {
    //     const myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");

    //     const raw = JSON.stringify({
    //         "user_uuid": UserUuid
    //     });

    //     const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: "follow"
    //     };

    //     fetch(`${REACT_APP_BASE_URL}/logout`, requestOptions)
    //         .then((response) => response.json())
    //         .then((result) => {
    //             if (result.Status == "1") {
    //                 navigate('/login');
    //                 // window.location.reload()
    //                 localStorage.clear()
    //                 toast.success(result.Msg, {
    //                     position: "top-right",
    //                     autoClose: 2000,
    //                     toastId: customId
    //                 });
    //             } else if (result.Status == "0") {
    //                 if (result.Msg === "Account Doesn't Exists") {
    //                     localStorage.clear()
    //                     // window.location.reload();
    //                     navigate("/login")
    //                 }
    //                 toast.success("Sucessfully Logged Out", {
    //                     position: "top-right",
    //                     autoClose: 2000,
    //                     toastId: customId
    //                 });
    //                 return;
    //             }
    //         })
    // }

    return (
        <>
            <div className='HomePage' >
                <div className="Banner">
                    <div className="s1">
                        <h1>Stay Connected <br /> everywhere</h1>
                        <p>
                            Get mobile data wherever you travel and avoid expensive roaming charges.
                        </p>

                        <p className='s1-para' >Instant. Easy. Affordable</p>

                        <div className="s1s1">
                            <img src={StarImage} alt="" />
                        </div>
                    </div>

                    <div className="s2">
                        <img src={StarImage} id='RatingImage' />
                        <img src={BannerImg} id='ManImage' />
                    </div>
                </div>
            </div>


            <FamousCountries />

            {/* <FamousRegions /> */}

            <AppSection />

            <div className="providers">
                <h2 style={{ color: '#292b2e', fontSize: '28px', fontWeight: '500' }} >Local networks, Giving you the best price</h2>
                <div className='providersLogo' >
                    <img src={Logo_1} id='companiesLogos' />
                    <img src={Logo_2} id='companiesLogos' />
                    <img src={Logo_3} id='companiesLogos' />
                    <img src={Logo_4} id='companiesLogos' />
                    <img src={Logo_5} id='companiesLogos' />
                    <img src={Logo_6} id='companiesLogos' />
                </div>
            </div>

            <WhyDigiesim />

            <FAQ />

            <Slider />

            <Footer />

            {/* <Modal open={sessionTimeoutState}>
                <Box className="SessionTimeOutModal" >
                    {Loading === true ? (
                        <div
                            style={{
                                height: isMobile ? "300px" : "500px",
                                width: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Bars
                                height="28"
                                width="28"
                                color="#9c88ff"
                                ariaLabel="bars-loading"
                                position="absolute"
                                visible={true}
                            />
                        </div>
                    ) : (
                        <div id="Content">
                            <img
                                src={SessionTimeOutImg}
                                style={{
                                }}
                            />

                            <H2 style={{ textAlign: "center" }}>
                                Your session has expired ..... !!!
                                <br /> Please log in again to continue.
                            </H2>

                            <Button
                                style={{ color: "red", fontSize: "16px", marginBottom: '20px' }}
                                variant="outlined"
                                color="inherit"
                                onClick={HandleLogout}
                            >
                                Log In Again
                            </Button>
                        </div>
                    )}
                </Box>
            </Modal> */}
        </>
    )
}

export default HomePage
