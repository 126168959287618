import React, { useState, useEffect } from 'react';
import { IconButton, Box, Button, TextField, Typography, Autocomplete, Icon, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Account.css';
import Footer from '../HomePage/Footer/Footer';
import { Accordion, AccordionSummary, AccordionDetails, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tag } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';


const Account = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const UserUuid = localStorage.getItem("UserUuid");

    const customId = "forNotShowingMultipleToast"
    const navigate = useNavigate();

    const [CurrentPassword, setCurrentPassword] = useState("");
    const [ViewCurrentPassword, setViewCurrentPassword] = useState(false);
    const [NewPassword, setNewPassword] = useState("");
    const [ViewNewPassword, setViewNewPassword] = useState(false);
    const [RetypeNewPassword, setRetypeNewPassword] = useState("")
    const [ViewRetypeNewPassword, setViewRetypeNewPassword] = useState(false);

    // Error message states start here 
    const [NewPasswordError, setNewPasswordError] = useState('');
    const [RetypePasswordError, setRetypePasswordError] = useState('');
    const [NewPasswordFlag, setNewPasswordFlag] = useState(true);
    const [RetypePasswordFlag, setRetypePasswordFlag] = useState(true)

    const maxPasswordLength = 16; // max password length for 16 character

    const [TabValue, setTabValue] = useState("1");

    const [paymentHistory, setPaymentHistory] = useState([]);
    const [ActivePlans, setActivePlans] = useState([])
    const [ExpiredPlans, setExpiredPlans] = useState([])
    const [UpcomingPlans, setUpcomingPlans] = useState([]);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailId, setEmailId] = useState("");
    const [iccid, setIccid] = useState("");

    const [totalDataHistory, setTotalDataHistory] = useState('');
    const [totalRemaningDataHistory, setTotalRemaningDataHistory] = useState('');

    const convertEpochToDate = (utcDateString) => {
        const date = new Date(utcDateString);

        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }
        return date.toLocaleString();
    };

    function convertPlanDate(dateString) {
        const date = new Date(dateString);

        if (isNaN(date)) {
            return "--";
        }

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };

        return date.toLocaleString('en-US', options);
    }

    const HandleTabChange = (event, newTab) => {
        setTabValue(newTab);
        setCurrentPassword('')
        setNewPassword('');
        setRetypeNewPassword('');

    };

    const showUserDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/user_details`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setFirstName(result.accounts.first_name);
                setLastName(result.accounts.last_name);
                setEmailId(result.accounts.email_id);
                setChooseIccids(result.accounts.iccid ? result.accounts.iccid : "Select ICCID")
                setIccid(result.accounts.iccid)
                if (result.accounts.iccid) {
                    ShowDataHistory(result.accounts.iccid, result.accounts.plan_id.split(',')[0]); // Only call if iccid exists
                }
            })
            .catch((error) => console.error(error));
    }

    // Password validations start here 

    const handleChangeNewPassword = (e) => {
        const inputValue = e.target.value;

        if (inputValue.length <= maxPasswordLength) {
            setNewPassword(inputValue);
        }

        if (inputValue.length < 6 || inputValue.length > maxPasswordLength) {
            setNewPasswordError("Password should be between 6 to " + maxPasswordLength + " characters");
            setNewPasswordFlag(false);
        } else {
            setNewPassword(inputValue);
            setNewPasswordError('');
            setNewPasswordFlag(true)
        }

    }

    const handleChangeRetypePassword = (e) => {
        const inputValue = e.target.value;

        if (inputValue.length <= maxPasswordLength) {
            setRetypeNewPassword(inputValue);
        }

        if (inputValue.length < 6 || inputValue.length > maxPasswordLength) {
            setRetypePasswordError("Password should be between 6 to " + maxPasswordLength + " characters");
            setRetypePasswordFlag(false);
        } else {
            setRetypeNewPassword(inputValue);
            setRetypePasswordError('');
            setRetypePasswordFlag(true);
        }

    }

    const handlePasswordEdit = () => {

        if (!CurrentPassword || !NewPassword || !RetypeNewPassword) {
            toast.warn('Please fill in all required fields', {
                position: 'top-right',
                toastId: customId,
                autoClose: 2000

            })
            return;
        }

        if (!NewPasswordFlag) {
            toast.warn("Password should be between 6 to " + maxPasswordLength + " characters", {
                position: 'top-right',
                toastId: customId,
                autoClose: 2000
            })
            return;
        }

        if (!RetypePasswordFlag) {
            toast.warn("Password should be between 6 to " + maxPasswordLength + " characters", {
                position: 'top-right',
                toastId: customId,
                autoClose: 2000
            })
            return;

        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            user_uuid: UserUuid,
            password: CurrentPassword,
            confirm_password: NewPassword,
            retype_confirm_password: RetypeNewPassword,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            `${REACT_APP_BASE_URL}/change_password`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    setCurrentPassword("");
                    setNewPassword("");
                    setRetypeNewPassword("")
                } else {
                    toast.error(result.Msg, {
                        toastId: customId,
                        position: "top-right",
                        autoClose: 2000,
                    });
                }
            });
    };

    const ShowPaymentHistory = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${REACT_APP_BASE_URL}/payment_history`, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setPaymentHistory(result.payment_history || []);

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const ShowDataHistory = (Iccid) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic Og==");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };


        fetch(`${REACT_APP_BASE_URL}/get_plan/${Iccid}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {

                const activePlans = result.plans.filter(plan => plan.network_status === "ACTIVE");
                const expiredPlans = result.plans.filter(plan => plan.network_status === "TERMINATED");
                const upcomingPlans = result.plans.filter(plan => plan.network_status === "NOT_ACTIVE")

                setActivePlans(activePlans);
                setExpiredPlans(expiredPlans);
                setUpcomingPlans(upcomingPlans);

                setTotalDataHistory(((result.plans[0].data_quota_bytes) / (1024 * 1024)).toFixed(2))
                setTotalRemaningDataHistory(((result.plans[0].data_bytes_remaining) / (1024 * 1024)).toFixed(2))

            })
            .catch(error => console.error('Error fetching data:', error));
    }

    useEffect(() => {
        ShowPaymentHistory()
        showUserDetails()
    }, [])

    const [TotalIccids, setTotalIccids] = useState([])
    const [ChooseIccids, setChooseIccids] = useState('Select ICCID');
    const [NoDataResponse, setNoDataResponse] = useState(false);

    // Getting the all iccid for particul user 
    const GetTotalIccids = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": UserUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/all_iccid`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Msg == "No Iccid found" || result.ResponseCode === 404) {
                    setNoDataResponse(true);
                    setTotalIccids([])
                } else {
                    setNoDataResponse(false)
                    setTotalIccids(result.user_sims)

                }
            })
            .catch((error) => {
            });
    }

    useEffect(() => {
        GetTotalIccids();
    }, [])

    const handleChooseIccidApi = (ChooseIccids) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": UserUuid,
            "iccid": ChooseIccids == "Select ICCID" ? "" : ChooseIccids,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };


        fetch(`${REACT_APP_BASE_URL}/choose_iccid`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == "1") {
                    showUserDetails();
                }
            })
            .catch((error) => console.error(error));
    }
    const handleNavigate = () => {
        navigate("/all_countries&regions");
    }


    return (
        <>
            <div className="Account">
                <h1>My digiesim</h1>
                <section className="profile">
                    <div className="profile-picture">
                        <div className="initials"> {firstName.charAt(0)}{lastName.charAt(0)}</div>
                    </div>

                    <div className="profile-info">

                        <h3> Name : <span> {firstName} {lastName}</span>  </h3>

                        <h3> Email Id : <span>{emailId} </span> </h3>

                        <h3>Total eSIMs :
                            <span>
                                {!NoDataResponse
                                    ? TotalIccids.map((item, index) =>
                                        ` ${item.iccid}-${item.country} ${index < TotalIccids.length - 1 ? " , " : ""}`
                                    )
                                    : " No eSIMs Available "}
                            </span>
                        </h3>

                        <FormControl sx={{ minWidth: 120, maxWidth: 500 }} size="small">
                            <p>Select an eSIM to view it's data.</p>
                            <Select
                                value={ChooseIccids}
                                onChange={(e) => {
                                    setChooseIccids(e.target.value);
                                    if (e.target.value != "Select ICCID") {
                                        handleChooseIccidApi(e.target.value)
                                    } else {
                                        showUserDetails();
                                    }
                                }}
                            >
                                <MenuItem value="Select ICCID"><em>-- Select eSIM --</em></MenuItem>
                                {
                                    TotalIccids.length > 0 ?
                                        TotalIccids.map((data, index) => {
                                            return <MenuItem value={data.iccid} key={index} > {data.iccid} - {(data.country)} </MenuItem>
                                        })
                                        : <MenuItem value="" disabled >No eSIM Available</MenuItem>
                                }
                            </Select>
                        </FormControl>

                        <h3>Selected eSIM :
                            <span> {iccid ? iccid : "N/A"}</span>
                        </h3>
                        {
                            ChooseIccids != "Select ICCID" &&
                            <>
                                <h3>Total Data (Current Plan) :
                                    <span>
                                        {
                                            totalDataHistory ? (totalDataHistory > 999 ? `${(totalDataHistory / 1000).toFixed(2)} GB` : ` ${totalDataHistory} MB`) : ' -- '
                                        }
                                    </span>
                                </h3>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            width: "100%",
                                            height: "20px",
                                            padding: "0px",
                                            marginBottom: "5px",
                                            padding: "0px 5px",
                                        }}
                                    >
                                        <p>Data Consumed</p>
                                        <p>Data Remaining</p>
                                    </div>

                                    <div style={{ display: "flex", width: "100%", borderRadius: '10px', overflow: 'hidden', border: '1px lightgrey solid', backgroundColor: "#e0e1dd" }}>
                                        <div
                                            style={{
                                                backgroundColor: "#778da9",
                                                borderTopLeftRadius: "10px",
                                                borderBottomLeftRadius: "10px",
                                                height: "12px",
                                                animation: "progressBar 1s ease-in-out forwards",
                                                width: `${((totalDataHistory - totalRemaningDataHistory) / totalDataHistory) * 100}%`,
                                            }}
                                        ></div>
                                        <div
                                            style={{
                                                backgroundColor: "#e0e1dd",
                                                borderTopRightRadius: "10px",
                                                borderBottomRightRadius: "10px",
                                                height: "12px",
                                                animation: "progressBar 1s ease-in-out forwards",
                                                width: `${(totalRemaningDataHistory / totalDataHistory) * 100}%`,
                                            }}
                                        ></div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            fontWeight: "300",
                                            fontSize: "14px",
                                            width: "100%",
                                            height: "20px",
                                            marginTop: '3px',
                                            padding: "0px 5px",
                                        }}
                                    >
                                        <p>
                                            {(totalDataHistory - totalRemaningDataHistory) > 999
                                                ? ((totalDataHistory - totalRemaningDataHistory) / 1000).toFixed(2) + " GB"
                                                : (totalDataHistory - totalRemaningDataHistory).toFixed(2) + " MB"
                                            }
                                        </p>
                                        <p>{(totalRemaningDataHistory > 999 ? `${(totalRemaningDataHistory / 1000).toFixed(2)} GB` : `${totalRemaningDataHistory} MB`)}  </p>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <br />
                    <Button variant="outlined"
                        onClick={() => handleNavigate()}
                        style={{ color: '#fff', border: '1px solid #00a1e4' , background:'#00a1e4'}}>
                        GET NEW PLAN
                    </Button>
                </section>

                <Box
                    sx={{ width: "100%", typography: "body1" }}
                    style={{
                        backgroundColor: "white",
                        padding: "10px",
                        borderRadius: "10px",
                    }}
                >
                    <TabContext value={TabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                                onChange={HandleTabChange}
                            >
                                <Tab label="PLAN" value="1" />
                                <Tab label="PAYMENTS" value="2" />
                                <Tab label="PASSWORD" value="3" />
                            </TabList>
                        </Box>

                        <TabPanel value="1">
                            <Accordion id="PlanCardAccordian" >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                >
                                    <h2> <Tag id="HashIcon" />   Active Plans </h2>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <div className='PlansBox'>
                                        {ActivePlans.length < 1 ? (
                                            <Typography variant="body2" color="textSecondary">-- No Active Plans available. --</Typography>
                                        ) : (
                                            ActivePlans.map((plan) => (
                                                <>
                                                    <div className="PlanName">
                                                        <h2>Plan Details <span style={{ color: 'green', marginLeft: '5px' }} > ( Active ) </span> </h2>
                                                        <p>Plan Name: <span> {plan.plan_type.plan_name} </span></p>
                                                        <p>Start Date: <br /> <span>  {convertPlanDate(plan.start_time)} </span> </p>
                                                        <p>End Date: <br /> <span>  {convertPlanDate(plan.end_time)} </span> </p>
                                                    </div>
                                                </>
                                            ))
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion id="PlanCardAccordian" style={{ marginTop: '10px' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                >
                                    <h2>  <Tag id="HashIcon" />  Upcoming Plans </h2>
                                </AccordionSummary>


                                <AccordionDetails>
                                    <div className='PlansBox'  >
                                        {UpcomingPlans.length < 1 ? (
                                            <Typography variant="body2" color="textSecondary">-- No Upcoming Plans available. --</Typography>
                                        ) : (
                                            UpcomingPlans.map((plan) => (
                                                <>
                                                    <div className="PlanName">
                                                        <h2>Plan Details <span style={{ color: 'indianred', marginLeft: '5px' }} >  ( Not Active ) </span> </h2>
                                                        <p>Plan Name: <span> {plan.plan_type.plan_name} </span></p>
                                                        <p>Purchase Date: <br /> <span>  {convertPlanDate(plan.start_time)} </span> </p>
                                                        <p>End Date: <br /> <span>  {convertPlanDate(plan.end_time)} </span> </p>
                                                    </div>
                                                </>
                                            ))
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion id="PlanCardAccordian" style={{ marginTop: '10px' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                >
                                    <h2>  <Tag id="HashIcon" />  Expired Plans </h2>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='PlansBox'  >
                                        {ExpiredPlans.length < 1 ? (
                                            <Typography variant="body2" color="textSecondary">-- No Expired Plans available. --</Typography>
                                        ) : (
                                            ExpiredPlans.map((plan) => (
                                                <>
                                                    <div className="PlanName">
                                                        <h2>Plan Details <span style={{ color: 'indianred', marginLeft: '5px' }} > ( Expired ) </span> </h2>
                                                        <p>Plan Name: <span> {plan.plan_type.plan_name} </span></p>
                                                        <p>Start Date: <br /> <span>  {convertPlanDate(plan.start_time)} </span> </p>
                                                        <p>End Date: <br /> <span>  {convertPlanDate(plan.end_time)} </span> </p>
                                                    </div>
                                                </>
                                            ))
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </TabPanel>

                        <TabPanel value="2">
                            <div>
                                {paymentHistory.length === 0 ? (
                                    <Typography color="textSecondary">No payment history available.</Typography>
                                ) : (
                                    paymentHistory.map((payment) => (
                                        <Accordion key={payment.plan_name} id="Accordian" >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                id="Accordian_Head"
                                            >
                                                <AccountBalanceWalletIcon id="Icon" />
                                                <div>
                                                    <h2>$ {payment.price}</h2>
                                                    <p>Payment Status: <span
                                                        style={{ color: payment.status ? "green" : "indianred" }}
                                                    > {payment.status ? payment.status : "Payment Failed"} </span> </p>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className='Accordion_body'>
                                                    <h3>Plan Name : {payment.plan_name}</h3>
                                                    <p>Payment Method:  <span> {payment.payment_method} </span> </p>
                                                    <p>Date: <span>{convertEpochToDate(payment.created_at)} </span> </p>
                                                    {payment.status === "No-Cost" ? <p>Email Id: <span style={{ textTransform: "lowercase" }}> {emailId} </span></p> :
                                                        <p>Email Id : <span> {payment.receipt_email ? payment.receipt_email : "--"} </span> </p>
                                                    }
                                                    <p>Payment Status: <span
                                                        style={{ color: payment.status ? "green" : "indianred" }}
                                                    >  {payment.status ? payment.status : "Payment Failed"} </span> </p>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                )}
                            </div>
                        </TabPanel>

                        <TabPanel value="3">
                            <div style={{ padding: '10px' }}>
                                <Typography>
                                    <h2
                                        style={{
                                            color: "#00a1e4",
                                            fontWeight: "500",
                                            fontSize: "22px",
                                        }}
                                    >
                                        Change Password
                                    </h2>
                                    <br />
                                    <div>
                                        <TextField
                                            className='updatePassword'
                                            type={ViewCurrentPassword ? "text" : "password"}
                                            label="Current Password"
                                            value={CurrentPassword}
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end" onClick={() => setViewCurrentPassword(!ViewCurrentPassword)}>
                                                            {ViewCurrentPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <div className='upadtePas' >
                                            <TextField
                                                style={{
                                                    width: "100%",
                                                    padding: "0px",
                                                }}
                                                type={ViewNewPassword ? "text" : "password"}
                                                label="New Password"

                                                value={NewPassword}
                                                // onChange={(e) => { setNewPassword(e.target.value) }}
                                                onChange={handleChangeNewPassword}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton edge="end" onClick={() => setViewNewPassword(!ViewNewPassword)}>
                                                                {ViewNewPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {NewPasswordError &&
                                                <p style={{ color: "red", fontSize: "14px", margin: '8px 0px 0px 0px ' }}>{NewPasswordError}</p>
                                            }
                                        </div>

                                        <div className='upadtePas' >
                                            <TextField
                                                style={{
                                                    width: "100%",
                                                    padding: "0px",
                                                }}
                                                type={ViewRetypeNewPassword ? "text" : "password"}
                                                label="Re-Type Password"

                                                value={RetypeNewPassword}
                                                // onChange={(e) => {setRetypeNewPassword(e.target.value)}}
                                                onChange={handleChangeRetypePassword}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton edge="end" onClick={() => setViewRetypeNewPassword(!ViewRetypeNewPassword)}>
                                                                {ViewRetypeNewPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {RetypePasswordError &&
                                                <p style={{ margin: '8px 0px 0px 0px', color: 'red', fontSize: '14px' }}>{RetypePasswordError}</p>
                                            }
                                        </div>

                                    </div>
                                    <br />
                                    <Button className='updatebtn'
                                        style={{
                                            backgroundColor: "#00a1e4",
                                            color: "white",
                                        }}
                                        onClick={handlePasswordEdit}
                                    >
                                        Edit Password
                                    </Button>
                                </Typography>
                            </div>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>

            <Footer />

        </>
    );
};

export default Account;
